// general routes
const Home = () =>
  import(/* webpackChunkName: "js/HomePage" */ '@pages/Home.vue');
const About = () =>
  import(/* webpackChunkName: "js/AboutPage" */ '@pages/About.vue');
const Terms = () =>
  import(/* webpackChunkName: "js/TermsPage" */ '@pages/Terms.vue');
const DasFakturen = () =>
  import(/* webpackChunkName: "js/DasFakturen" */ '@pages/DasFakturen.vue');
const DasFakturenVis = () =>
  import(/* webpackChunkName: "js/DasFakturen" */ '@pages/DasFakturenVis.vue');
const DasVoorraad = () =>
  import(/* webpackChunkName: "js/DasVoorraad" */ '@pages/DasVoorraad.vue');
const Debiteur = () =>
  import(/* webpackChunkName: "js/Debiteur" */ '@pages/Afas/Debiteur.vue');
const Magazijn = () =>
  import(/* webpackChunkName: "js/Magazijn" */ '@pages/Magazijn/Magazijn.vue');
const Bestellingen = () =>
  import(
    /* webpackChunkName: "js/Bestellingen" */ '@pages/Magazijn/Bestellingen.vue'
  );
const Bestelvoorstel = () =>
  import(
    /* webpackChunkName: "js/Bestelvoorstel" */ '@pages/Bestelvoorstel.vue'
  );
const Inboeken = () =>
  import(/* webpackChunkName: "js/Inboeken" */ '@pages/Inboeken.vue');
const Opzoeken = () =>
  import(/* webpackChunkName: "js/Opzoeken" */ '@pages/Opzoeken.vue');
// const VerwerkBestelvoorstel = () =>
//   import(
//     /* webpackChunkName: "js/VerwerkBestelvoorstel" */ '@pages/VerwerkBestelvoorstel.vue'
//   );
const FinancialMutations = () =>
  import(
    /* webpackChunkName: "js/FinancialMutations" */ '@pages/Afas/FinancialMutations.vue'
  );
const OrderList = () =>
  import(/* webpackChunkName: "js/OrderList" */ '@pages/OrderList.vue');
const ExternalOrder = () =>
  import(/* webpackChunkName: "js/ExternalOrder" */ '@pages/ExternalOrder.vue');
const ExternalOrderInkoop = () =>
  import(
    /* webpackChunkName: "js/ExternalOrder" */ '@pages/ExternalOrderInkoop.vue'
  );
const Dashboard = () =>
  import(/* webpackChunkName: "js/DashboardPage" */ '@pages/Dashboard.vue');
const Login = () =>
  import(/* webpackChunkName: "js/LoginPage" */ '@pages/Login.vue');
const ForgotPassword = () =>
  import(
    /* webpackChunkName: "js/ForgotPasswordPage" */ '@pages/ForgotPassword.vue'
  );
const ResetPassword = () =>
  import(
    /* webpackChunkName: "js/ResetPasswordPage" */ '@pages/ResetPassword.vue'
  );
const Register = () =>
  import(/* webpackChunkName: "js/RegisterPage" */ '@pages/Register.vue');
const VerifyEmail = () =>
  import(/* webpackChunkName: "js/VerifyEmailPage" */ '@pages/VerifyEmail.vue');

const Settings = () =>
  import(
    /* webpackChunkName: "js/SettingsPage" */ '@pages/settings/Settings.vue'
  );
const Profile = () =>
  import(
    /* webpackChunkName: "js/ProfilePage" */ '@pages/settings/Profile.vue'
  );
const Password = () =>
  import(
    /* webpackChunkName: "js/PasswordPage" */ '@pages/settings/Password.vue'
  );
const Account = () =>
  import(
    /* webpackChunkName: "js/AccountPage" */ '@pages/settings/Account.vue'
  );
const NotFound = () =>
  import(/* webpackChunkName: "js/NotFoundPage" */ '@pages/NotFound.vue');

const AdminLayout = () =>
  import(
    /* webpackChunkName: "js/AdminLayoutPage" */ '@/layouts/AdminLayout.vue'
  );
const Admin = () =>
  import(/* webpackChunkName: "js/AdminPage" */ '@/views/admin/Admin.vue');
const Users = () =>
  import(/* webpackChunkName: "js/UsersPage" */ '@/views/admin/Users.vue');
const Roles = () =>
  import(/* webpackChunkName: "js/RolesPage" */ '@/views/admin/Roles.vue');
const Permissions = () =>
  import(
    /* webpackChunkName: "js/PermissionsPage" */ '@/views/admin/Permissions.vue'
  );
const AppSettings = () =>
  import(
    /* webpackChunkName: "js/AppSettingsPage" */ '@/views/admin/AppSettings.vue'
  );
const MagazijnLogs = () =>
  import(
    /* webpackChunkName: "js/MagazijnLogsPage" */ '@/views/admin/MagazijnLogs.vue'
  );
const Wiki = () =>
  import(
    /* webpackChunkName: "js/MagazijnLogsPage" */ '@/views/admin/Wiki.vue'
  );
// const PhpInfo = () =>
// import(/* webpackChunkName: "js/PhpInfoPage" */ '@/views/admin/PhpInfo.vue');
import { defineAsyncComponent } from 'vue';

import auth from '@/middleware/auth';
import guest from '@/middleware/guest';
import roleAdmin from '@/middleware/roleAdmin';
import roleSuperAdmin from '@/middleware/roleSuperAdmin';
import roleUser from '@/middleware/roleUser';

export default [
  {
    path: '/',
    // component: () => Home,
    component: Home,
    name: 'home',
  },
  {
    path: '/about',
    component: About,
    name: 'about',
  },
  {
    path: '/terms',
    component: Terms,
    name: 'terms',
  },
  // {
  //   path: '/afas',
  //   component: Afas,
  //   name: 'afas',
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  // {
  //   path: '/afas/:id/:afas_order_id',
  //   component: AfasFillOrder,
  //   props: (route) => ({
  //     afas_order_id: route.params.afas_order_id,
  //   }),
  //   name: 'afasfillorder',
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  {
    path: '/dasfakturen',
    component: DasFakturen,
    name: 'dasfakturen',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/dasfakturenvis',
    component: DasFakturenVis,
    name: 'dasfakturenvis',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/dasvoorraad',
    component: DasVoorraad,
    name: 'dasvoorraad',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/nieuwedebiteur',
    component: Debiteur,
    name: 'debiteur',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/financialmutations',
    component: FinancialMutations,
    name: 'financialmutations',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/bestelvoorstel',
    component: Bestelvoorstel,
    name: 'bestelvoorstel',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/inboeken',
    component: Inboeken,
    name: 'inboeken',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/opzoeken',
    component: Opzoeken,
    name: 'opzoeken',
    meta: {
      middleware: [auth],
    },
  },
  // {
  //   path: '/bestelvoorstel/verwerk-bestelvoorstel/:bestelvoorstel_dataset_id',
  //   name: 'verwerk-bestelvoorstel',
  //   meta: {
  //     middleware: [auth],
  //   },
  //   component: VerwerkBestelvoorstel,
  //   props: (route) => ({
  //     bestelvoorstel_dataset_id: route.params.bestelvoorstel_dataset_id,
  //   }),
  // },
  {
    path: '/orderlist',
    component: OrderList,
    name: 'orderlist',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/magazijn/:id?', // The '?' makes the 'id' parameter optional
    component: Magazijn,
    name: 'magazijn',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/bestellingen',
    component: Bestellingen,
    name: 'bestellingen',
    meta: {
      middleware: [auth],
    },
  },
  // {
  //   path: '/external',
  //   component: External,
  //   name: 'external',
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  {
    path: '/externalorder/:order_id?',
    component: ExternalOrder,
    name: 'externalorder',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/externalorderinkoop/:order_id?',
    component: ExternalOrderInkoop,
    name: 'externalorderinkoop',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/dashboard',
    component: Dashboard,
    name: 'dashboard',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/admin',
    component: AdminLayout,
    children: [
      {
        path: '',
        name: 'admin',
        component: Admin,
      },
      {
        path: 'users',
        name: 'users',
        component: Users,
      },
      {
        path: 'roles',
        name: 'roles',
        component: Roles,
      },
      {
        path: 'permissions',
        name: 'permissions',
        component: Permissions,
      },
      {
        path: 'phpinfo',
        name: 'phpinfo',
        component: defineAsyncComponent(() =>
          import('@/views/admin/PhpInfo.vue'),
        ),
      },
      {
        path: 'app-settings',
        name: 'app-settings',
        component: AppSettings,
      },
      {
        path: 'magazijnlogs',
        name: 'magazijnlogs',
        component: MagazijnLogs,
      },
      {
        path: 'wiki',
        name: 'wiki',
        component: Wiki,
      },
    ],
    meta: {
      middleware: [roleAdmin],
    },
  },
  {
    path: '/login',
    component: Login,
    name: 'login',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'forgot-password',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/reset-password/:token',
    props: (route) => ({
      token: route.params.token,
      email: route.query.email,
    }),
    component: ResetPassword,
    name: 'reset-password',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/register',
    component: Register,
    name: 'register',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/verify-email/:id/:hash',
    props: (route) => ({
      id: route.params.id,
      hash: route.params.hash,
    }),
    component: VerifyEmail,
    name: 'verify-email',
  },
  {
    path: '/settings',
    component: Settings,
    redirect: {
      name: 'account',
    },
    name: 'settings',
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: 'account',
        component: Account,
        name: 'account',
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'profile',
        component: Profile,
        name: 'profile',
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'password',
        component: Password,
        name: 'password',
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'NotFound',
  },
];
