<template>
  <div :class="bgClass">
    <div id="top_border" />
    <OctoCat
      v-if="showOctoCat"
      repo="jeremykenedy/laravel-spa"
      position="right-top"
      animated-eye
      animated-ear
      animated-arm
      show-face
      :dark-mode="darkMode"
    />
    <VerifyNotice
      v-if="
        $route.name !== 'home' &&
        $route.name !== 'verify-email' &&
        authenticated &&
        user &&
        user.id &&
        !user.email_verified_at &&
        !isAdminPage
      "
      :id="user.id"
    />
    <AppNav v-if="!isAdminPage" />
    <div class="w-full">
      <router-view v-slot="{ Component }" :class="isAdminPage ? '' : 'p-6'">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <AppFooter v-if="!isAdminPage" class="sticky top-[100vh] w-full" />
    <AppToast />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppNav from '@components/AppNav.vue';
import AppFooter from '@components/AppFooter.vue';
import VerifyNotice from '@components/VerifyNotice.vue';
import OctoCat from '@components/OctoCat.vue';

export default {
  components: {
    AppNav,
    VerifyNotice,
    AppFooter,
    OctoCat,
  },
  data() {
    return {
      isDebugMode: false,
    };
  },
  computed: {
    ...mapGetters({
      darkMode: 'auth/darkMode',
    }),
    currentRouteName() {
      return this.$route.name;
    },
    bgClass() {
      return this.isDebugMode
        ? 'min-h-screen bg-green-500 dark:bg-green-800'
        : 'min-h-screen bg-white dark:bg-slate-800';
    },
    isAdminPage() {
      if (
        this.currentRouteName == 'admin' ||
        this.currentRouteName == 'roles' ||
        this.currentRouteName == 'permissions' ||
        this.currentRouteName == 'settings' ||
        this.currentRouteName == 'users' ||
        this.currentRouteName == 'app-settings' ||
        this.currentRouteName == 'phpinfo'
      ) {
        return true;
      }
      return false;
    },
    showOctoCat() {
      if (
        this.currentRouteName == 'home' ||
        this.currentRouteName == 'about' ||
        this.currentRouteName == 'terms'
      ) {
        return false;
      }
      return false;
    },
  },
  mounted() {
    // this.getWebdasConfig();
  },
  methods: {
    getWebdasConfig: function (config) {
      const url = '/system/config';
      const csrfToken = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content');

      fetch(url, {
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': csrfToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Network response was not ok');
        })
        .then((responseJson) => {
          if (responseJson && typeof responseJson.app_debug !== 'undefined') {
            this.isDebugMode = responseJson.app_debug;
          } else {
            throw new Error('Invalid JSON response');
          }
        })
        .catch((error) => {
          console.error('Error fetching config:', error);
        });
    },
    ...mapActions({
      popToast: 'toast/popToast',
    }),
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
