<template>
  <footer :class="footerClass">
    <div class="border-t py-4" :class="borderClass">
      <p
        class="text-center text-sm leading-6 text-slate-500 dark:text-gray-300"
      >
        &copy; {{ theYear }} {{ company }}. Alle rechten voorbehouden. |
        Disclaimer | Privacy | Feedback
      </p>
      <p
        v-if="roles.superAdmin"
        class="text-center text-sm leading-6 text-slate-500 dark:text-gray-300"
      >
        Status :
        <a id="afasstatuslink" style="color: gray" @click="checkafasstatus"
          >[AFAS]</a
        >
        <a
          id="unipartstatuslink"
          style="color: gray"
          @click="checkunipartstatus"
        >
          [JLR UNIPART]
        </a>
        <a
          id="allmakesstatuslink"
          style="color: gray"
          @click="checkallmakesstatus"
          >[ALLMAKES]</a
        >
        [BRITPART]
      </p>
    </div>
  </footer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AppFooter',
  components: {},
  props: {
    footerClass: {
      type: String,
      default: 'max-w-container mx-auto mt-32 w-full px-4 sm:px-6 lg:px-8',
    },
    borderClass: {
      type: String,
      default: 'border-slate-900/5 dark:border-slate-700',
    },
  },
  setup() {
    return {};
  },
  data() {
    return {
      company: 'ET Coevorden',
    };
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      roles: 'auth/roles',
    }),
    theYear() {
      const startYear = '2023';
      const currentYear = new Date().getFullYear();
      if (currentYear == startYear) {
        return currentYear;
      }
      return `${startYear}-${currentYear}`;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  beforeUnmount() {},
  updated() {},
  methods: {
    checkafasstatus: async function () {
      document.getElementById('afasstatuslink').style.color = 'gray';
      try {
        const response = await fetch('https://afasstatus.nl/');
        if (response.status === 200) {
          const html = await response.text();
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, 'text/html');
          const statusContent = doc.getElementsByClassName('statuscontent')[0];
          //console.log(statusContent);
          if (statusContent) {
            const statusText = statusContent.querySelector('p').innerHTML;
            if (
              statusText.includes('Goed') &&
              statusText.includes('Alle systemen werken correct')
            ) {
              document.getElementById('afasstatuslink').style.color = 'green';
            } else {
              document.getElementById('afasstatuslink').style.color = 'red';
            }
          } else {
            document.getElementById('afasstatuslink').style.color = 'red';
          }
        } else {
          document.getElementById('afasstatuslink').style.color = 'red';
        }
      } catch (error) {
        console.error(error);
        document.getElementById('afasstatuslink').style.color = 'red';
      }
    },
    checkunipartstatus: async function () {
      document.getElementById('unipartstatuslink').style.color = 'gray';
      await fetch('responsejlr.txt')
        .then((response) => response.text())
        .then((text) => {
          try {
            const data = JSON.parse(text);
            if (data.errorid === 109 && data.message === 'Wrong password') {
              document.getElementById('unipartstatuslink').style.color = 'red';
            } else {
              document.getElementById('unipartstatuslink').style.color =
                'green';
            }
          } catch (error) {
            document.getElementById('unipartstatuslink').style.color = 'green';
          }
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style scoped></style>

<style lang="scss" scoped></style>
